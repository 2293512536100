.Menu-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: $menu-width;
  transition: $menu-transition-duration width ease;
  //overflow: hidden;
  z-index: 20;
}

.Menu-title{
  font-size: 22px;
  min-width: 200px;
}

.Menu {
  background-color: white;
  width: 100%;
  height: 100%;
  color: $color-gray;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
  overflow: hidden;

  & > ul {
    list-style: none;
    padding: 0;
    margin-top: 2rem;

    & > li {
      transition: .6s all ease;
      border-right: 4px solid transparent;

      & > a {
        padding: .8rem 1rem;
        display: flex;
        cursor: pointer;
        width: 100%;
        box-sizing: border-box;
        color: $color-gray;
        text-decoration: none;

        svg {
          font-size: 36px;
          margin-left: 2rem;
          transition: $menu-transition-duration margin-left ease;
        }

        span {
          font-size: 18px;
          font-weight: 500;
          margin-top: 6px;
          margin-left: 10px;
        }
      }

      &:hover {
        background-color: $color-lightgreen;
        border-color: $main-green;

        svg {
          color: $main-green;
        }
      }
    }
  }

}

.Menu-toggleClose, .Menu-toggleCloseMobile {
  display: none;
}

.Menu-head {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $header-height;

  a {
    display: block;
  }

  svg {
    font-size: 30px;
    cursor: pointer;
  }
}

.Menu-logo {
  max-width: 14rem;
  width: 100%;
  cursor: pointer;
}

@media screen and (min-width: 767px) and (max-width: 1280px) { // MAC
  .Home:not(.is-menu-opened) .Menu-wrapper {
    width: 4rem;

    .Menu {
      & > ul > li > a > span {
        display: none;
      }

      & > ul > li > a > svg {
        margin-left: 6px;
      }
    }

    .Menu-logo {
      display: none;
    }

    .Menu-toggleClose {
      margin-left: 0;

      svg {
        transform: rotate(180deg);
        margin-left: -10px;
        margin-right: 0;
      }
    }
  }

  .Menu-toggleClose {
    display: block;
    margin-left: 1rem;

    & > svg {
      color: $main-green;
      transition: $menu-transition-duration transform ease;
      margin-right: -10px;
    }
  }

  .Menu-logo {
    max-width: 14rem;
  }
}

@media screen and (max-width: 767px) { //PHONES
  .Home.is-menu-opened .Menu-wrapper {
    display: block;
    width: 100%;

    & > ul > li > a {
      justify-content: center;

      & > span {
        display: block;
        font-size: 20px;
        min-width: 120px;
        text-align: left;
      }
    }
  }

  .Home:not(.is-menu-opened) .Menu-wrapper {
    width: 0;
  }

  .Menu-toggleClose {
    display: none;
  }

  .Menu-toggleCloseMobile {
    display: block;
    float: right;
    margin-right: 20px;

    & > svg {
      color: $main-green;
    }
  }

  .Menu-head {
    justify-content: space-between;
    margin-left: 1rem;
    margin-right: .5rem;
  }
}