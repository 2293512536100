.Document {
  display: flex;
  height: $main-height;
  position: absolute;
  padding: $main-padding-y $main-padding-y;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: $color-lightgray;
  z-index: 2;
  border-left: 3px solid white;
}

.Document-forms {
  max-width: 35rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .form-select {
    margin-bottom: 1rem;;
  }
}

.Document-fileUpload {
  margin-right: auto;
}

.Document-editForm {
  display: flex;
  flex-direction: column;
}

.Document-formActions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.Document-editForm .Document-formActions {
  justify-content: flex-end;
}

.Document-preview {
  margin-left: 2rem;
  flex-grow: 1;
  min-width: 35rem;
  display: flex;
  flex-direction: column;
}

.Document-previewContainer {
  box-shadow: $box-shadow-inset;
  background-color: white;
  flex-grow: 1;

  iframe {
    border: 1px solid #c5c5c5;
    padding: 0;

  }
}

.Document-previewImg {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
  }
}

.Document-infos {
  display: flex;
  justify-content: space-between;
  margin-bottom: .5rem;
}

.Document-returnLink {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  svg {
    font-size: 1.5rem;
    margin-right: .4rem;
  }

  & > span {
    font-size: 1rem;
    margin-bottom: .1rem;
  }
}

.Document-closeLink {
  color: $color-red !important;
  font-weight: bold;
}

.Document-validationStatus {
  color: $main-green;
  font-weight: 600;
  font-size: 1rem;

  &.is-refused {
    color: $color-red;
  }
}

.Document-signature {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}

.coverFromLeft {
  &-enter {
    transform: translateX(100%);
  }

  &-enter-active {
    transition: 1s transform ease;
    transform: translateX(0%);
  }

  &-exit {
    transform: translateX(0%);
  }

  &-exit-active {
    transition: 1s transform ease;
    transform: translateX(100%);
  }
}
