.GKToggleableInput {
  cursor: pointer;
  max-height: 16px;
  display: inline-block;
  span{
   max-height: 16px;
  }
  input{
    max-height: 16px;
    box-sizing: border-box;
    min-width: 40px;
  }
  transition: all 0.4s;
  &:hover{
    //opacity: 0.8;
    text-decoration: underline;
  }
}

