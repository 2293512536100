.SelectFlat {
  gap: 1rem;

  &,
  &-column {
    display: flex;
    flex-direction: column;
  }

  &-column {
    gap: 0.5rem;
  }

  &-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
