.TaskFilters {
  margin-top: 4rem;
  text-align: left;

  ul {
    list-style: none;
    padding: 0 .5rem;
  }

  li {
    display: flex;
    align-items: center;
  }
}

.TaskFilters-title {
  font-size: 1.1rem;
  margin: 0 .5rem;
}

.TaskFilters-badge {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-left: auto;
}
