.GKInput-select__control {
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid #ccc !important;
  color: $color-darkgray;

  &--is-focused {
    border-color: $main-green !important;
  }
}

.GKInput-select__value-container {
  padding-left: 20px !important;
}

.GKInput-select__option {
  &--is-focused {
    background-color: transparentize($main-green, .9) !important;
  }

  &--is-selected {
    background-color: $main-green !important;
  }
}

.GKInput-select__menu {
  border-radius: 10px !important;
  overflow: hidden !important;
}

.GKInput-select__menu-list {
  padding: 0 !important;
}
.GKInput-select__single-value{
  color: $color-darkgray!important;
}