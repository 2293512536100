@keyframes configure-clockwise {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes configure-xclockwise {
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-315deg);
  }
}

.LoaderCube-configure-border-1 {
  width: 100px;
  height: 100px;
  padding: 10px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-pink;
  animation: configure-clockwise 3s ease-in-out 0s infinite alternate;
}

.LoaderCube-configure-border-2 {
  width: 100px;
  height: 100px;
  padding: 10px;
  left: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $main-green;
  transform: rotate(45deg);
  animation: configure-xclockwise 3s ease-in-out 0s infinite alternate;
}

.LoaderCube-configure-core-1 {
  width: 100%;
  height: 100%;
  background: white;
}

.LoaderCube-configure-core-2 {
  width: 100%;
  height: 100%;
  background: white;
}
