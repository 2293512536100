.Todos{

}

.Todos-head{
  display: flex;
  align-items: center;
  h1{
    margin: 0;
  }
  button{
    margin-right: 15px;
  }
}

.Todos-month-head{
  display: flex;
  align-items: center;
  padding: 5px 0;
  button{
    margin: 0 5px;
  }
}

.Todos-urgent{
  margin: 15px 0;
}

.Todos-year-navigate{
  width: 100%;
}

.Todos-month h2{
  margin: 8px 0;
}
.Todos-month-todolist{
  background-color: transparentize($medium-champagne, 0.6);
  min-height: 80px;
  padding: 5px;
}
.Todos-month-empty{
  text-align: center;
  height: 80px;
  font-size: 32px;
  padding-top: 20px;
  box-sizing: border-box;
}