.EmployeesModal .GK-modal{
  min-width: 480px;
}
.EmployeesModal ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
  li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-lightgray;
    padding: 10px 20px;
    margin-bottom: 20px;
    span{
      color: $color-red;
    }
    .EmployeesModal-current, span, svg{
      cursor: pointer;
      transition: 0.3s all;
      &:hover{
        opacity: 0.8;
      }
    }
  }
}
.EmployeesModal-team{
  width: 80px;
  text-align: left;
}
.EmployeesModal .GKInput{
  min-width: 240px;
}