.ProjectComment {
  .GKInput{
    width: 100%;
  }
  .form-textarea{
    width: 100%;
    border: 1px solid #f1f1f1;
  }
  h3{
    border: none;
    padding-bottom: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: $body-color;
  }
}

.ProjectComment-title.isActive{
  color: $main-green;
}

.Project-comments .ProjectComment:last-child{
  .MuiAccordion-root{
    border-bottom: none;
  }
}
.Project-comments .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}