.Field {
  $that: &;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  &-label {
    font-size: 0.9rem;
    font-weight: 600;
    white-space: nowrap;
  }

  &--error {
    #{$that}-label {
      color: red;
    }
  }
}
