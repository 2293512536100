.MuiSwitch-track {
  width: auto !important;
  height: 19px !important;
  position: absolute !important;
  top: 1px;
  left: 11px;
  right: 10px;
  border-radius: 11px !important;
  margin: 9px 0;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: $main-green !important;

  & + .MuiSwitch-track {
    background-color: $color-green !important;
  }
}