.Confirm {
  $that: &;

  &--error {
    #{$that}-label {
      color: red;
    }
  }

  &-errorMessage {
    margin-left: 2rem;
  }
}
