.Alerts{
  position: absolute;
  z-index: 5000;
  right: 10px;
  top: 80px;
}

.Alerts-item{
  background-color: $main-green;
  padding: 10px;
  border-radius: 1rem;
  box-shadow: $box-shadow;
  color: white;
  max-width: 260px;
  margin-bottom: 10px;
  opacity: 1;
  transition: 600ms all;
  top: 0;
  position: relative;
  &.closing{
    top: -1000px
  }
}

.Alerts-item-head{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  span{
    display: flex;
    align-items: center;
    font-weight: 600;
    img, svg{
      margin-right: 10px;
    }
    img{
      padding: 4px;
      box-sizing: border-box;
    }
  }
}

.Alerts-item-head-icon{
  width: 40px!important;
  height: 40px!important;
  background-color: $main-green;
  border-radius: 40px;
  &.icon-gray{
    background-color: $color-darkgray;
  }
}
.Alerts-item-head-close{
  cursor: pointer;
  &:hover{
    opacity: 0.6;
  }
}
.Alerts-item-task{
  margin-top: 30px;
}
.Alerts-item-customer{
  text-align: right;
  margin-top: 30px;
}