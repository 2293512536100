
.DocumentList {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.DocumentList-item {
  display: flex;
  line-height: 1.4rem;
  font-size: 16px;

  svg {
    font-size: 1rem;
    margin-right: .5rem;
    margin-top: 2px;
  }

  &.isActive {
    color: $main-green;
    text-decoration: underline;
    cursor: pointer;
  }
}