.Home {
  transition: opacity 1s ease;
  position: relative;
  z-index: 1;
  background: white;
  background-color: $color-lightgray;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &.router-enter {
    opacity: 0;
  }

  &.router-enter-active {
    opacity: 1;
  }
}

.Home-container {
  padding: $main-padding-y $main-padding-x;
  margin-left: $menu-width;
  min-height: $main-height;
  transition: $menu-transition-duration margin-left ease;
  position: relative;
  overflow-x: hidden;

  &.role_customer {
    margin-left: 14rem;
    min-height: auto;
  }
}

.Home-container h1{
  font-size: 22px;
  font-weight: 400;
  > svg{
    vertical-align: bottom;
    margin-bottom: 5px;
  }
}

/* GENERAL MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (min-width: 767px) and (max-width: 1280px) { // MAC
  .Home:not(.is-menu-opened) .Home-container {
    margin-left: 4rem;

    &.role_customer {
      margin-left: 14rem;
    }
  }
}

@media screen and (max-width: 767px) { //PHONES
  .Home .Home-container {
    margin-left: 0;
  }
}
