.Summary {
  &-title {
    font-weight: 600;
    font-size: 0.9rem;
    border: none !important;
    margin: 10px 0 0 0!important;
    padding: 0 !important;
  }

  &-infos {
    font-size: 0.65rem;
  }

  &-logo {
    background-color: $main-green;
    display: inline-flex;
    border-radius: 100%;
    padding: 6px;
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &-price {
    margin-top: 0.5rem;
  }
}
