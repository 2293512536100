$left-width: 8.5rem;

.Task {
  margin-top: 1rem;
  padding-top: .5em;
  padding-bottom: .5rem;
  border: 1px solid transparent;
}

.Task-head {
  cursor: pointer;
  display: flex;
  align-items: center;
  .icon-button{
    color: $color-gray;
    &.hasComment{
      color: $main-green;
    }
  }
}

.Task-head-left {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: stretch;
  width: $left-width;
}

.Task-time {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.Task-icon {
  padding: 10px;
  border: 1px solid $color-gray;
  border-radius: 40px;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  svg{
    margin-left: -2px;
    margin-top: -2px;
    color: $color-gray;
  }
  &.Task-status_late svg{
    color: $color-darkyellow;
  }
  &.Task-status_veryLate svg{
    color: $color-red;
  }
  &.Task-status_now svg{
    color: $color-blue;
  }
  &.Task-status_advance svg{
    color: $main-green;
  }
}

.Task-status {
  width: .3rem;
  align-self: stretch;
  margin: 0 .5rem;
  border-radius: .2rem;
}

.Task-infos {
  display: flex;
  flex-direction: column;
}

.Task-title {
  font-weight: 600;
  font-size: 1rem;
}

.Task-user {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 1rem;
  svg {
    margin-right: .3rem;
  }
}

.Task-processBtn {
  margin: 0 1rem;

  svg {
    margin-right: -.5rem;
  }
}

.Task-content {
  padding-top: 1rem;
  padding-bottom: .4rem;
  margin-left: $left-width;
}

.Task-content-head {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  margin-bottom: 1rem;

  & > li {
    display: flex;
    align-items: center;
    color: $color-gray;

    &:not(:first-child) {
      margin-left: 1rem;
    }
    & > a{
      display: flex;
      align-items: center;
      color: $color-gray;

    }
    svg {
      padding-right: .8rem;
    }
  }
}

.Task-content-body {
  display: flex;
  align-items: flex-start;
}

.Task-project {
  border: 1px solid $color-gray;
  border-radius: 15px;
  padding: .5rem;
  margin-right: 2rem;

  & > span {
    display: block;
    white-space: nowrap;
  }
}

.Task-project-title {
  font-size: 1.1rem;
  font-weight: 600;
}

.Task-comment {
  flex-grow: 1;
  border: 1px solid $color-gray;
  border-radius: 15px;
  padding: 0.5rem;
  margin-left: auto;
  &:focus{
    border: 1px solid $main-green;
    outline: none;
  }
}

.taskAppear {
  &-enter {
    opacity: 0;
    transform: translateY(600%);
  }

  &-enter-active {
    transition-duration: 300ms;
    transition-property: opacity, transform;
    transition-timing-function: ease;
    opacity: 1;
    transform: translateY(0);
  }
}

.MuiPopover-paper{
  padding: 5px;
  min-width: 80px!important;
  text-align: center;
  margin-left: -30px;
  font-size: 16px!important;
}

.Task.taskPrioritary{
  //border: 1px dashed $color-red!important;
  box-shadow: 0 0 0 0 $color-red;
  animation: pulseTask 1s infinite;
}

@keyframes pulseTask {
  0% {
    box-shadow: 0 0 0 0 rgba(211, 47, 47, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(211, 47, 47, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(211, 47, 47, 0);
  }
}

.Task-datetimepicker{
  visibility: hidden;
  width: 1px;
}
