.GKInput-container {
  display: block;
  width: 100%;
  background: transparent;
  box-sizing: border-box;
  margin-top: .5rem;

  input {
    border: 1px solid $color-gray-2;
    border-radius: 6px;
    padding: 10px 20px;
    color: $color-darkgray;
    outline: none;
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
    &:focus {
      border-color: $main-green;
      background-color: white;
    }
  }
  input[type=number]{
    width: 200px;
  }

}