.TodoItem{
  display: flex;
  justify-content: space-between;
  box-shadow: 2px 2px 4px $color-gray;
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: white;
  height: 40px;
  overflow: hidden;
}
.TodoItem-actions{
  display: flex;
}

.TodoItem-cat{
  color: white;
  min-width: 40px;
  text-align: center;
  padding-top: 8px;
}
.TodoItem-cat svg{
  color: white;
}
.TodoItem-cat-classic{
  background-color: $blue-sapphire;
}
.TodoItem-cat-spending{
  background-color: $gamboge;
}
.TodoItem-cat-urgent{
  background-color: $main-red;
}
.TodoItem-cat-project{
  background-color: $viridian-green;
}
.TodoItem-cat-event{
  background-color: $color-purple;
}
.TodoItem-cat-loop{
  background-color: $color-darkgray;
}


.TodoItem-content{
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.TodoItem-content-name{
  font-weight: 600;
}
.TodoItem-content-date{
  padding-top: 3px;
  font-size: 11px;
  min-width: 25px;
}
.TodoItem-content-date svg{
  margin-right: -15px;
}
.TodoItem-content-date span{
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  bottom: 5px;
  left: -5px;
  text-align: center;
}

.TodoItem-actions button{
  width: 30px;
  height: 30px;
  margin-top: 5px;
}

// DONE ITEMS STYLE

.TodoItem-done{
  background-color: #bfe0c1;
  box-shadow: none;
}
.TodoItem-done .TodoItem-cat{
  opacity: 0.4;
}
.TodoItem-done button{
  background-color: transparent!important;
  box-shadow: none;
  svg{
    color: $color-gray;
  }
}
.TodoItem-done .TodoItem-content-name{
  font-weight: 400;
}