.CompleteProjectInfos .GKInput{
  margin-bottom: 20px;
  width: 100%;
}

.CompleteProjectInfos textarea{
  border: 1px solid $color-gray-2;
  margin-top: 10px;
  width: 100%;
}

.CompleteProjectInfos-row{
  display: flex;
}


.CompleteProjectInfos-col{
  width: 50%;
  padding: 0 20px 0 0;
}

.CompleteProjectInfos-submit{
  display: flex;
  justify-content: flex-end;
}

