.DocumentItem {
  width: 170px;
  height: 170px;
  margin-left: 2rem;
  margin-bottom: 1rem;
  padding: 0;
  position: relative;
  border-radius: 10px;

  &.needsValidation{
    border: 2px solid $color-red;
  }
}

.DocumentItem-title {
  display: block;
  text-align: center;
  color: $color-gray;
  overflow-wrap: break-word;
}

.DocumentItem-main {
  height: calc(100% - 1rem);
  display: flex;
  flex-direction: column;
  padding: .5rem;
  border-radius: 10px;
  border: 1px solid transparent;

  &:hover {
    .DocumentItem-toggleMenu svg {
      color: white;
    }

    .DocumentItem-main-hover {
      opacity: 1;
    }
  }

  &.is-to-be-validated:not(:hover) {
    border-color: $color-red;
  }
}

.DocumentItem-main-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;

  button svg {
    color: $color-gray;
  }

  .icon {
    border: 2px solid $main-green;
    width: 1.9rem;
    height: 1.9rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.isGreen {
      background: $main-green;
    }

    svg {
      width: 1.4rem;
      height: 1.4rem;
      fill: $main-green;
    }

    img {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}

.DocumentItem-main-foot {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 24px;
}

.DocumentItem-main-menu {
  user-select: none;
  position: absolute;
  top: 50px;
  right: -46px;
  z-index: 2;
  box-shadow: $box-shadow-accent;
  border-radius: 10px;
  padding: .5rem 0;

  span {
    width: 20px;
    height: 20px;
    background-color: white;
    display: block;
    position: absolute;
    top: -10px;
    left: 34px;
    transform: rotate(45deg);
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.22);
  }

  a, button {
    display: block;
    padding: .2rem 1rem;
    border: none;
    background: none;
    color: $color-gray;
    transition: all 0.3s;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;

    &:hover{
      color: $main-green;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }
}

.DocumentItem-main-hover {
  position: absolute;
  top: -1px;
  bottom: -2px;
  left: -1px;
  right: -1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: .5rem;
  border: 1px solid #adadadcc;
  border-radius: 10px;
  background-color: #adadadcc;
  transition: all 0.3s;
  opacity: 0;

  p {
    text-align: center;
    color: white;
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 700;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.DocumentItem-type {
  display: block;
  margin: auto;
  height: 3rem;
}

.DocumentItem-toggleMenu {
  position: relative;
  margin-right: -.5rem;
  z-index: 1;
}

.DocumentItem-form {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: .5rem;
  border-radius: 10px;
  border: 1px solid $main-green;
  background-color: lighten($main-green, 40);

  &.is-danger {
    background-color: lighten($color-red, 40);
    border-color: $color-red;

    .DocumentItem-form-title, .DocumentItem-form-close {
      color: $color-red;
    }
  }
}

.DocumentItem-form-center {
  margin-top: auto;
  margin-bottom: auto;
}

.DocumentItem-form-close {
  position: absolute;
  right: .5rem;
  top: .5rem;
  height: 24px;
  color: $main-green;
}

.DocumentItem-form-title {
  display: block;
  margin-top: .5rem;
  text-align: center;
  font-size: .9rem;
  font-weight: bold;
  color: $color-green;
}

.DocumentItem-form-email {
  display: block;
  margin-top: .5rem;
  font-size: .7rem;
  background: white;
  padding: .2rem;
  text-align: center;
  border-radius: 15px;
  border: 0;
  color: $body-color;
  width: calc(100% - .4rem);
}
