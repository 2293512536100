.form-input {
  display: flex;
  align-items: center;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border-radius: 30px;
  border: 2px solid transparent;
  background-color: #f1f1f1;
  box-sizing: border-box;

  input{
    border: 0;
    outline: none;
    background: transparent;
    width: 100%;
  }

  &.is-focused {
    border-color: $main-green;
    background-color: white;
  }
}

.form-textarea{
  border: 1px solid $color-gray-2;
  border-radius: 15px;
  padding: 0.5rem;
  margin-left: auto;
  box-sizing: border-box;
  font-family: Arial;
  font-size: 14px;
  color: $color-darkgray;
  &:focus{
    border: 1px solid $main-green!important;
    outline: none;
  }
}

.datetimeInput{
  background-color: #f1f1f1;
  border-radius: 20px;
  .MuiInput-underline:not(.Mui-disabled):before{
    border: none!important;
  }
  .MuiInput-underline:after{
    border: none!important;
  }
  input{
    padding: 10px;
  }
}

.form-msg {
  display: block;
  font-weight: bold;
  font-size: .9rem;

  &.is-success {
    color: $main-green;
  }

  &.is-error {
    color: $color-red;
  }
}

.life-datetimepicker{
  display: inline-block;
  width: 100%;
  input{
    border-radius: 6px;
    border: 1px solid $color-gray-2;
    align-items: center;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    width: 100%;
    cursor: pointer;
    padding-top: 10px;
    height: 19px;
    font-size: 14px;
    color: $color-darkgray;
  }
  .MuiInput-underline:after, .MuiInput-underline:before{
    display: none;
  }
}

.Mui-focused input{
  border: 1px solid $main-green;
}