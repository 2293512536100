.Checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  input {
    display: none; // Bad for accessibility
  }

  svg {
    margin-right: .3rem;
    color: $main-green;
  }

  &.is-right {
    flex-direction: row-reverse;

    svg {
      margin-right: 0;
      margin-left: .3rem;
    }
  }
}