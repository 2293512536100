.Header {
  height: $header-height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: $menu-width;
  background-color: white;
  transition: $menu-transition-duration margin-left ease;

  &.role_customer {
    margin-left: 0 !important;
  }
}

.Header-profile {
  display: flex;
  align-items: center;
  height: 100%;
  &:hover {
    background-color: $color-lightgreen;
    cursor: pointer;
  }
}

.Header-avatar {
  max-height: 3.5rem;
  border-radius: 50px;
  margin-right: 1rem;
}

svg.Header-avatar{
  font-size: 50px;
}

.Header-profile-infos span {
  display: block;
}

.Header-profile-name {
  color: $main-green;
  font-weight: 600;
  font-family: $font-title;
}

.Header-profile-email {
  color: $color-darkgray;
}

.Header-profile-card {
  display: flex;
}

.Header-toggleProfileMenu {
  margin-left: 1rem;
  margin-right: 1rem;
  max-height: 100px;
  //margin-right: 50px;
  svg {
    font-size: 40px;
    color: $color-gray;
    transition: .3s transform ease;
  }

  &.active svg {
    transform: rotate(180deg);
  }

  &:focus{
    outline: none;
  }
}

.Header-profile-menu {
  box-shadow: -2px 3px 7px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  background-color: white;
  top: $header-height;
  min-width: 200px;
  z-index: 19;

  ul {
    padding: 10px;
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
      & > a, & > button {
        padding: .5rem 1rem;
        display: flex;
        cursor: pointer;
        width: 100%;
        box-sizing: border-box;
        color: $color-gray;
        text-decoration: none;
        background: transparent;
        border: 0;

        span {
          font-size: 16px;
          font-weight: 400;
          margin-top: 4px;
          margin-left: 10px;
        }
      }

      &:hover {
        background-color: $color-lightgreen;
        border-right: 4px solid $main-green;

        svg{
          color: $main-green;
        }
      }
    }
  }
}

.Header-toggleMenuMobile {
  margin-left: 20px;
  color: $main-green;
  display: none;

  & > svg {
    font-size: 30px;
  }
}

.Header.role_customer {
  .progressBarContainer {
    height: 100%;
    min-height: 100%;
    padding: 0;
    flex-grow: 1;
    border: 0;
  }

  .progressBar {
    width: 100%;
    max-width: unset;
  }

  .progressStep {
    width: calc(100% / 6);
  }

  .progressBg {
    margin: 0 calc(100% / 6 / 2);
    max-width: calc(100% - (100% / 6));

    $step_number: 6;
    @for $i from 1 through $step_number {
      &.stepIncoming_#{$i} {
        width: calc((#{$step_number} - #{$i} - 1) * (100% / #{$step_number}));
        margin-left: calc(#{$i} * (100% / #{$step_number}) + (100% / (#{$step_number} * 2)));
      }

      &.stepCurrent_#{$i} {
        width: calc(100% / 6);
        margin-left: calc(#{$i} * (100% / #{$step_number}) - (100% / (#{$step_number} * 2))});
      }

      &.stepComplete_#{$i} {
        width: calc((#{$i} - 1) * (100% / 6));
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .Header.role_customer {
    margin-bottom: $header-height;
    flex-wrap: wrap;

    .Header-profile {
      margin-right: 0;
    }

    .progressBarContainer {
      background: $color-lightgray;
      border-bottom: 1px solid $color-gray-2;
      order: 4;
      width: 100%;
    }

    .progressLabel {
      display: none;
    }
  }
}

@media screen and (min-width: 1200px) {
  .progressBar-tooltip {
    span {
      display: none;
    }

    p:first-of-type {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 767px) { //PHONES
  .Header {
    margin-left: 0;
  }

  svg.Header-avatar {
    margin-right: 0;
  }

  .Header-toggleMenuMobile {
    display: block;
  }

  .Header-toggleProfileMenu {
    margin: 0 0.5rem;
  }
}
