// Colors

/* SCSS HEX */
$rich-black: #001219ff;
$blue-sapphire: #005f73ff; // PRIMARY COLOR
$main-green: #2a9d8f; // SUCCESS COLOR
$viridian-green: #0a9396ff;
$middle-blue-green: #caf3e2; // SECONDARY COLOR
$medium-champagne: #e9d8a6ff;
$gamboge: #ee9b00ff; // WARNING COLOR
$alloy-orange: #ca6702ff;
$mahogany: #bb3e03ff;
$main-red: #ae2012ff; // DANGER COLOR
$ruby-red: #9b2226ff;


// black and white
$color-lightgray: #f1f1f1;
$color-lightgray-2: #F2F2F2;
$color-gray: #9e9e9e;
$color-gray-2: #ccc;
$color-darkgray: #777;
$color-gray-text: #444;

$link-color: $color-darkgray;
$link-hover-color: $main-green;

//**********************************************************************************




$color-lightgreen: #F3F8EB;
$color-green: #8bbf45;
$color-palegreen: #a6d568;
$color-red: #d32f2f;
$color-purple: #6418C3;
$color-odoo: #875a7b;
$color-pink: #E55386;
$color-yellow: #ffeb3b;
$color-darkyellow: #fbc02d;
$color-blue: #2196f3;
$body-color: #585858;


// Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');

$font-body: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

$font-size-root: .85rem;

$font-title: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

$font-btn: 'Raleway', sans-serif;

// Shadows
$box-shadow: 0 2px 12px 0 rgba(0,0,0,.08);
$box-shadow-inset: inset 0 2px 12px 0 rgba(0,0,0, .3);
$box-shadow-accent: 0 2px 4px rgba(0, 0, 0, 0.22), -3px -2px 6px rgba(0, 0, 0, 0.14);
$opacity-hover: 0.8;

// Media queries mixins
@mixin on-tablet {
  @media screen and (max-width: 1030px) {
    @content;
  }
}

@mixin on-mobile {
  @media screen and (max-width: 767px) {
    @content;
  }
}

// Config
$menu-width: 17rem;
$header-height: 4.5rem;
$main-height: calc(100vh - #{$header-height} - 3rem);
$main-padding-y: 1rem;
$main-padding-x: 1rem;
$menu-transition-duration: .4s;

// COULEURS DES STATUS
$color-status-noshow: #d32f2f;
$color-status-analysisplu: #9c27b0;
$color-status-upsell: #3949ab;
$color-status-opportunite: #00bcd4;
$color-status-created: #2196f3;
$color-status-signed: #8bbf45;
$color-status-accepte-mairie: #8bbf45;
$color-status-wc: #9e9d24;
$color-status-declarationtravaux: #fdd835;
$color-status-mairie: #fdf335;
$color-status-planificiation: #fb8c00;
$color-status-attente-50: #fb8c00;
$color-status-closed: #616161;
$color-status-loss: #000000;


// COULEURS DES TACHES
$color-task-wcr: #d32f2f;
$color-task-analysisplu: #9c27b0;
$color-task-r: #00bcd4;
$color-task-pm: #2196f3;
$color-task-insertion: #8bbf45;
$color-task-wc: #9e9d24;
$color-task-decharge: #fdd835;
$color-task-envoyermairie: #fb8c00;