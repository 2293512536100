/* ISOK */
.Login {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $main-green;
  background-image: url('../../assets/login-bg.jpg');
  background-size: cover;
  height: 100vh;
  font-size: 1rem;

  &.router-exit-active .Login-card {
    transition: transform 1s ease;
    transform: translateX(-200%);
  }
}

.Login-container {
  max-width: 45rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Login-card {
  background-color: white;
  padding: 2rem;
  border-radius: 30px;
  max-width: 28rem;
  overflow: hidden;
}

.Login-logo {
  max-width: 100%;
  width: 25rem;
}

.Login-text {
  display: block;
  font-weight: 400;
  color: $color-gray-text;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  letter-spacing: 1px;
  text-align: center;
  font-size: 1.2rem;
  font-family: $font-title;
}

.Login-form {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 28rem;

  .form-input {
    margin-bottom: 1rem;

    input {
      padding: .9rem 0;
      font-weight: 500;
      transition: all 0.3s;
    }
  }
}

.Login-actions {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;

  a {
    font-size: 16px;
    margin-top: 3px;
    display: block;
  }

  span {
    color: $color-darkgray;
    font-size: 14px;
    display: inline-block;
  }
}

.Login-toggleVisiblePassword {
  cursor: pointer;
  margin-top: 3px;

  svg{
    color: $main-green;
  }
}

.Login-submit {
  margin-top: 1.5rem;
  font-size: 1.1rem;
  padding: 1rem;
}

.Login-error {
  margin: 0;
  text-align: center;
}

.Login-success {
  margin: 0;
  text-align: center;
}

.loginmode-enter .Login-form {
  transform: translateX(100%);
}

.loginmode-enter-active .Login-form {
  transform: translateX(0%);
}

.loginmode-exit .Login-form {
  transform: translateX(0%);
}

.loginmode-exit-active .Login-form {
  transform: translateX(-110%);
}

.loginmode-enter-active, .loginmode-exit-active {
  transition: height .5s;

  .Login-form {
    transition: transform .5s;
  }
}

@include on-tablet {
 .Login-container{
   max-width: 100%;
 }
}

@include on-mobile {
  .Login-container{
    background: $blue-sapphire; /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, $blue-sapphire, $middle-blue-green); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, $blue-sapphire, $middle-blue-green); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

  .Login-card {
    padding: 0;
    background-color: transparent;
    text-align: center;
    width: 100%;
  }

  .Login-text {
    color: white;
    width: 80%;
    margin: 0 auto 1.5rem;
  }

  .Login-logo {
    max-width: 90%;
  }

  .Login-form {
    width: 90%;
    margin: 0 auto;

    button {
      margin-top: 1rem;
    }
  }

  .Login-actions {
    input {
      width: 15px;
      height: 15px;
    }

    a, span {
      margin: 4px 0 0 5px;
    }
    a{
      color: white;
      &:hover{
        color: $color-lightgray;
      }
    }
  }
}
