.Price {
  text-align: right;
  font-size: 0.8rem;

  &-sup {
    color: $main-green;
    border-bottom: 2px solid $main-green;
    padding: 0.2rem 1.3rem;
  }

  &-sub {
    color: $color-red;
    padding: 0.2rem 1.3rem;
  }

  &-diff {
    padding: 0.2rem 1.3rem;
  }
}
