.Snackbar {
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column-reverse;
  list-style: none;
  padding: 0;
  margin: 0;
}

.Snackbar-item {
  padding: .5rem 1rem;
  margin-top: .5rem;
  border-radius: 5px;
  font-weight: bold;
  font-size: .9rem;
  background: rgba($color-gray, .15);
  border: 1px solid $body-color;
  display: flex;
  align-items: center;

  svg {
    font-size: 1.2rem;
    margin-left: -.5rem;
    margin-right: .5rem;
  }

  &.is-success {
    background-color: lighten($main-green, 40);
    border-color: $main-green;
    color: $main-green;
  }

  &.is-error {
    background-color: lighten($color-red, 40);
    border-color: $color-red;
    color: $color-red;
  }

  &-appear {
    animation-name: bounce;
  }

  &-appear-active {
    animation-duration: .5s;
    animation-fill-mode: both;
  }
}

@keyframes bounce {
  0% { transform: translateY(-100px) }
  50% { transform: translateY(0px) }
  60% { transform: translateY(-5px) }
  100% { transform: translateY(0px) }
}