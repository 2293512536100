.TodoSummary{
  margin-top: 15px;
}
.TodoSummary-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  p{
    font-size: 18px;
    margin: 0 0 0 15px;
    span{
      font-weight: 300;
    }
  }
}