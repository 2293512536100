/* ISOK */
.button {
  display: flex;
  align-items: center;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  padding: 0.5rem 2rem;
  background-color: $main-green;
  box-shadow: $box-shadow;
  border-radius: 6px;
  cursor: pointer;
  transition: box-shadow .3s, background-color .3s;
  justify-content:center;
  max-height: 40px;
  font-family: $font-btn;

  &:hover {
    box-shadow: 0 0px 0px rgba(0, 0, 0, .01), 0 0 8px rgba(0, 0, 0, .01);
    background-color:  lighten($main-green, 10);
  }

  &:focus {
    outline: none;
  }

  svg {
    font-size: 2rem;
  }

  & > span {
    display: block;
    //margin-bottom: 2px;
  }

  &.is-loading {
    background-image: url('../../assets/loader.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 65% 65%;

    & > * {
      visibility: hidden;
    }
  }

  &.is-small {
    padding: .2rem 1rem;
    svg {
      font-size: 1.5rem;
    }
  }

  &.is-secondary {
    background-color: $middle-blue-green;
    &:hover {
      background-color: lighten($middle-blue-green, 10);
    }
  }

  &.is-danger {
    background-color: $main-red;
    &:hover {
      background-color: lighten($main-red, 10);
    }
  }

  &.is-warning {
    background-color: $gamboge;
    &:hover {
      background-color: lighten($gamboge, 10);
    }
  }

  &.is-primary {
    background-color: $blue-sapphire;
    &:hover {
      background-color: lighten($blue-sapphire, 10);
    }
  }

  &.is-light {
    background-color: white;
    color: $color-gray;
    &:hover {
      background-color: darken(white, 10);
    }
  }

  &.is-light-outline {
    background-color: white;
    border: 1px solid darken(white, 20);
    color: $color-gray;
    &:hover {
      background-color: darken(white, 20);
    }
  }

  &.has-icon {
    & > svg {
      &:first-child {
        margin-left: -.5rem;
      }

      &:last-child {
        margin-right: -.5rem;
      }
    }
  }

  &.is-icon {
    width: 40px;
    height: 40px;
    & > svg {
      &:first-child {
        margin-left: -1.5rem;
      }
      &:last-child {
        margin-right: -1.5rem;
      }
    }
  }

  &.img-button{
    padding-left: 0.5rem;
    img{
      background-color: $color-darkgray;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      margin-right: 10px;
    }
  }

  &.disabled{
    cursor: not-allowed;
    background-color: $color-gray;
    &:hover{
      background-color: $color-gray;
    }
  }
}

.icon-button {
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  &:focus {
    outline: none;
  }
}

.button.life-has-icon{
  svg{
    margin-right: 10px;
    font-size: 24px;
  }
}

.ChoiceButton{
  color: $color-darkgray;
  background-color: white;
  box-shadow: none;
  width: 180px;
  margin-right: 20px;
  margin-bottom: 10px;
  &.ChoiceButton-success{
    border: 2px solid $main-green;
    &:hover{
      background-color: lighten($main-green, 30);
    }
    &.selected{
      color: white;
      background-color: $main-green;
    }
  }
  &.ChoiceButton-warning{
    border: 2px solid $gamboge;
    &:hover{
      background-color: lighten($gamboge, 30);
    }
    &.selected{
      color: white;
      background-color: $gamboge;
    }
  }
  &.ChoiceButton-danger{
    border: 2px solid $main-red;
    &:hover{
      background-color: lighten($main-red, 30);
    }
    &.selected{
      color: white;
      background-color: $main-red;
    }
  }
}


