$stepNumber : 15;
$totalWidth : 1500px;
$stepWidth : $totalWidth/$stepNumber;

.progressBarContainer{
  height: 80px;
  min-height: 80px;
  box-sizing: border-box;
  z-index: 1;
  background-color: #fff;
  border-bottom: 1px solid $color-gray-2;
  display: flex;
  align-items: center;
}

.progressBar{
  max-width: $totalWidth;
  position: relative;

  &.step {
    width: 20%;
  }
}

.progressBar-tooltip {
  padding: .5rem .2rem;

  span {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1rem;
  }

  p {
    font-size: .8rem;
    line-height: .8rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.progressBg {
  width: 100%;
  height: 2px;
  background-color: $main-green;
  z-index: -1;
  margin: 0 $stepWidth/2;
  max-width: $totalWidth - $stepWidth - 20px;
  transition: all 0.8s;
  position: absolute;
  bottom: 11px;

  &.current{
    width: 0;
    margin-top: -2px;
    background-color: $color-pink;
  }

  &.incoming{
    width: 0;
    margin-top: -2px;
    background-color: $color-gray-2;
  }

  @for $i from 1 through $stepNumber {
    &.stepIncoming_#{$i} {
      width: calc(#{($stepNumber - $i) * ($stepWidth)});
      margin-left: calc(#{($i - 1) * ($stepWidth) + 40px});
    }
    &.stepCurrent_#{$i} {
      width: $stepWidth;
      margin-left: calc(#{($i - 1) * ($stepWidth) - 60px});
    }
    &.stepComplete_#{$i} {
      width: calc(#{($i - 2) * ($stepWidth)});
    }
  }
}
.progressStepsContainer{
  display: flex;
}

.progressStep {
  width: $stepWidth;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  &.isComplete {
    .progressIndicator {
      background-color: $main-green;
      border-color: $main-green;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.isCurrent {
    .progressIndicator {
      background-color: $color-pink;
      border-color: $color-pink;
    }

  }
}

.progressIndicator {
  transition-duration: 0.8s;
  width: 25px;
  height: 25px;
  margin-top: auto;
  border: 5px solid $color-gray-2;
  border-radius: 50%;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg{
    font-size: 15px;
    color: #fff;
  }
}

.progressLabel {
  padding: .2rem 0;
  margin: auto 0;
}


