.WelcomeCall-content{
  h3{
    color: $main-green;
    border-bottom: 0;
  }
  h2{
    font-size: 18px;
  }
}
.WelcomeCallDP{
  input{
    width: 300px;
  }
}
.WelcomeCallDP-submit-container{
  display: flex;
  padding-top: 10px;
}
.WelcomeCall-forms{
  display: flex;
  justify-content: space-between;
}

.WelcomeCall-state, .WelcomeCallDP-state{
  display: flex;
  justify-content: space-between;
}
.WelcomeCall-state button{
  width: 240px;
}
.WelcomeCallDP-state button{
  width: 400px;
}

.WelcomeCall-state-analyseplu-resume{
  margin-bottom: 20px;

}
.WelcomeCallDP .GKInput.hidden{
  display: none;
}
.WelcomeCallDP-insertion{
  margin-left: 40px;
  padding-top: 24px;
  &.hidden{
    display: none;
  }
}
.WelcomeCallDP-form{
  display: flex;
  align-items: center;
}

.WelcomeCall-state-analyseplu-resume{
  border: 1px solid $main-green;
  border-radius: 15px;
  padding: 10px;
  margin: 20px 0;
  min-height: 100px;
}


.WelcomeCallEx-buttons{
  button{
    width: 200px;
    margin-bottom: 10px;
  }
}