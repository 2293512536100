.ProjectSearch {
  position: relative;
  width: 25rem;
  margin-left: 2rem;
  .form-input {
    height: 2.7rem;
    padding-left: .9rem;

    input {
      padding: .9rem 0;
      font-weight: 500;
      transition: all 0.3s;
    }

    svg {
      font-size: 1.6rem;
      margin-right: .5rem;
      color: $main-green;
    }
  }

  .appearDown {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
  }
}

.ProjectSearch-results {
  margin: 0;
  list-style: none;
  position: absolute;
  z-index: 200;
  top: 3.5rem;
  left: 0;
  padding: .7rem;
  padding-bottom: 0;
  width: 130%;
  background: white;
  box-shadow: $box-shadow;
  border-radius: 5px;
  max-height: 70vh;
  overflow-y: auto;
}

.ProjectSearch-item {
  position: relative;
  border: 1px solid $color-gray;
  border-radius: 5px;
  margin-bottom: .5rem;

  &:last-child {
    margin-bottom: 1rem;
  }

  & > a {
    display: flex;
    align-items: center;
    padding: .5rem;

    &:hover {
      color: inherit;
    }
  }

  $status: created, noshow, signed, analysisplu, declarationtravaux, planificiation, closed, loss, upsell, wc , mairie, attente-50, accepte-mairie;

  $status-colors: (
      created : $color-status-created,
      noshow :$color-status-noshow,
      signed : $color-status-signed,
      accepte-mairie : $color-status-accepte-mairie,
      analysisplu : $color-status-analysisplu,
      declarationtravaux : $color-status-declarationtravaux,
      planificiation : $color-status-planificiation,
      attente-50 : $color-status-attente-50,
      closed : $color-status-closed,
      loss : $color-status-loss,
      upsell : $color-status-upsell,
      wc : $color-status-wc,
      mairie : $color-status-mairie
  );

  @each $statut in $status {
    &.is-#{$statut} {
      &:hover {
        border-color: map-get($status-colors, $statut);
      }
      .ProjectSearch-item-status {
        background: map-get($status-colors, $statut);
      }
      .ProjectSearch-item-statusLabel {
        color: map-get($status-colors, $statut);
      }
    }
  }




}

.ProjectSearch-item-head {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: .65rem;
  white-space: nowrap;
  min-width: 60px;

  strong {
    font-size: .7rem;
  }
  span{
    white-space: normal;
  }
}

.ProjectSearch-item-status {
  width: .3rem;
  align-self: stretch;
  margin: 0 .5rem;
  border-radius: .2rem;
}
.ProjectSearch-item-statusLabel {

  font-size: .6rem;
}
.ProjectSearch-item-right-part{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
}

.ProjectSearch-item-employee {
  display: flex;
  align-items: flex-end;
  margin-top: 8px;
  svg {
    margin-right: 4px;
    margin-bottom: -2px;
  }
}



.ProjectSearch-item-body {
  display: flex;
  flex-direction: column;
  span {
    font-size: .7rem;
  }
}

.ProjectSearch-item-name{
  display: block;
  min-width: 200px;
  span {
    margin-left: .4rem;
  }
}

.ProjectSearch-item-phone{
  svg{
    width: 16px;
    height: 16px;
    margin-top: 4px;
    margin-right: 4px;
  }
  span{
    display: flex;
    align-items: center;
  }
}

.projectSearchAppear {
  &-enter {
    max-height: 0;
    opacity: 0;
  }

  &-enter-active {
    transition: 300ms opacity ease 400ms, 500ms max-height ease;
    max-height: 4rem;
    overflow: hidden;
    opacity: 1;
  }

  &-exit {
    transition: 300ms opacity ease, 500ms max-height ease 200ms;
    max-height: 4rem;
    opacity: 1;
    overflow: hidden;
  }

  &-exit-active {
    max-height: 0;
    opacity: 0;
  }
}