.TodayRateModal{
  min-width: 300px;
}

.TodayRateModal-ratings{
  svg{
    font-size: 36px;
  }
}

.TodayRateModal-empty{
  color: $color-gray-2;
}

.TodayRateModal-star-full{
  color: $color-darkyellow;
}

.TodayRateModal-rest-full{
  color: $color-green;
}

.TodayRateModal-addict-pauses{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 280px;
  margin-top: 20px;
}

.TodayRateModal-addict-pauses-item{
  width: 50%;
  padding: 0 10px;
  box-sizing: border-box;
  p{
    margin-bottom: 0;
  }
  button{
    margin: 10px auto;
    max-height: 48px;
    width: 64px;
    &.is-light{
      box-shadow: none;
      background-color: white!important;
    }
    &.is-danger{
      box-shadow: none;
      background-color: lighten($main-red, 10)!important;
    }
  }
}