.DocumentUpload-open {
  & > svg {
    font-size: 2rem;
  }
}
.DocumentUpload-content {
  position: relative;

  & > .appearDown {
    position: absolute;
    right: 0;
    z-index: 200;
  }
}

.DocumentUpload-modal {
  padding: 1rem;
}

.DocumentsForm {
  display: flex;
  flex-direction: column;
  width: 20rem;

  .form-select {
    margin-bottom: 1rem;
  }

  .form-msg {
    padding-bottom: 1rem;
  }
}

.DocumentsForm-submit {
  align-self: flex-end;
}