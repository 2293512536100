.Config {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  &--clickable {
    cursor: pointer;
    user-select: none;
  }

  &--error {
    box-shadow: inset 0 0 0 2px red;
  }

  &-summary {
    min-width: 293px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    cursor: initial;
    user-select: initial;
    border-left: 1px solid #ddd;
    padding-left: 1rem;
    gap: 1rem;
  }

  &-row {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }
}


.CustomProducts-wrapper{
  display: flex;
}
.CustomProducts-list{
  display: flex;
  .Chip:last-child{
    margin-right: 15px;
  }
}
.CustomProducts-modal{
  width: 300px;
  .GKInput{
    margin-bottom: 20px;
    input{
      width: 300px!important;
    }
    textarea{
      display: block;
      width: 300px;
      margin-top: .5rem;
    }
  }
}