.Alert {
  border-left: 4px solid;
  padding: 10px 10px 8px 5px;
  margin: 10px 0;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  &.success{
    border-color: $main-green;
    background-color: transparentize($main-green, 0.85);
    color: $main-green;
  }
  &.warning{
    border-color: $color-darkyellow;
    background-color: transparentize($color-darkyellow, 0.85);
    color: $color-darkyellow;
  }
  &.danger{
    border-color: $color-red;
    background-color: transparentize($color-red, 0.85);
    color: $color-red;
  }
  &.secondary{
    border-color: $color-darkgray;
    background-color: transparentize($color-darkgray, 0.85);
    color: $color-darkgray;
  }
  svg{
    margin-top: -4px;
    margin-right: 4px;
  }
  &.full{
    height: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    svg{
      display: none;
    }
    .button{
      margin-top: 50px;
    }
  }
}

