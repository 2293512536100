.Project {
  h2 {
    margin: 0;
    font-weight: 400;
    color: $color-darkgray;
  }
  h3{
    font-weight: 400;
  }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.Project-clientInfos {
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: .5rem;
  background: $main-green;
  color: white;
  font-weight: bold;

  li {
    display: flex;
    flex-basis: 50%;

    span {
      margin: .2rem .5rem 0 .5rem;
    }
  }
}

.Project-content {
  margin-top: 1.5rem;
  display: flex;
}

.Project-actions {
  width: 100%;
  min-width: 50%;
  min-height: 720px;

  h3{
    border-bottom: 0;
    border-top: 1px solid $color-lightgray;
    padding: 1rem 0 0 0;
    margin-top: 1rem;
  }


}
.Project-action-form-part{
  display: flex;
}

.Project-right {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  width: 75%;

  .DocumentList {
    margin-right: 1rem;

    ul {
      height: 10rem;
      overflow-y: auto;
    }
  }

  .Calendar {
    height: 10rem;
  }
}
.Project.canceled .Project-right{
  opacity: 0.6;
}

.Project-configurations{
  margin-top: 1rem;
  display: flex;
  .card{
    width: 100%;
    display: flex;
  }
  img{
    background-color: $main-green;
    width: 50px;
    height: 50px;
    border-radius: 40px;
    padding: 10px;
    margin-right: 20px;
  }
  p{
    margin: 0;
  }
}
.Project-configurations-name{
  b{
    font-size: 18px;
  }
  p:nth-child(1){
    margin-bottom: 10px;
  }
}

.Project-configurations-options{
  border-left: 1px solid $color-gray;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
  margin-left: 20px;
  p{
    font-size: 12px;
    b{
      font-size: 16px;
    }
  }
}

.Project-configurations-price{
  min-width: 80px;
}


.Project-rightCols {
  margin-top: 1rem;
  display: flex;
  align-items: flex-start;

  &>div {
    flex: 50%;
  }
}

.Project-comments {
  margin-top: 1rem;

  .MuiAccordion-root{
    box-shadow: none;
    border-bottom: 1px solid $color-lightgray-2;
    &:before{
      display: none;
    }
  }
  .MuiAccordionSummary-content{
    margin: 4px 0;
    p{
      font-size: 20px;
    }
  }


  p {
    margin: .4rem 0;
  }
}

.Project-comments-text {
  max-height: 5rem;
  overflow-y: auto;
}

.Project-delete-link{
  color: $color-gray;
  display: flex;
  justify-content: flex-end;
  p{
    width: 160px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    transition: 0.2s all;
    &:hover{
      opacity: 0.8;
    }
  }
  p.disabled{
    cursor: default;
    width: 300px;
    opacity: 1 !important;
  }
}

.Project-canceled-alert{
  border: 4px dotted $color-red;
  padding: 20px;
  margin: 10px 0;
  &>div{
    display: flex;
  }
  img{
    margin-right: 20px;
  }

}

.Project-canceled-alert-desc{
  p:nth-child(1){
    font-size: 18px;
    font-weight: 600;
    color: $color-red;
  }
}

.Project-canceled-reniew-button{
  justify-content: flex-end;
}

.Project.canceled .Project-actions {
  height: 250px;
}

.ProjectCurrentAction{
  background-color: $color-pink;
  padding: 10px 20px;
  color: white!important;
}