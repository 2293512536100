.NavBar {
  list-style-type: none;
  display: flex;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 0;
  user-select: none;

  li {
    padding: .5rem;
    border: 1px solid $color-gray;
    border-right: none;
    color: $color-darkgray;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;
    font-size: .9rem;
    width: 8rem;
    background-color: white;

    &:first-child {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }

    &:last-child {
      border-right: 1px solid $color-gray;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    }

    &:hover {
      background-color: lighten($main-green, 10);
      color: white;
      border-color: lighten($main-green, 10);

      & + li:not(.is-active) {
        border-left-color: lighten($main-green, 10);
      }
    }

    &.is-active {
      background-color: $main-green;
      color: #fff;
      border-color: $main-green;

      & + li {
        border-left-color: $main-green;
      }
      svg{
        color: #fff;
      }
    }

    svg{
      color: $main-green;
      vertical-align: top;
      margin-top: -3px;
      margin-right: 6px;
    }
  }
}

