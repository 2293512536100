.Chip {
  $that: &;
  color: $body-color;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 1px;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  user-select: none;
  cursor: default;
  display: flex;
  align-items: center;

  &--initial {
    color: #fff;
    background-color: $color-darkyellow;
    border-color: $color-darkyellow;
  }

  &--selected {
    color: #fff;
    background-color: $main-green;
    border-color: $main-green;
  }

  &--clickable {
    cursor: pointer;

    &:hover {
      background-color: darken(#fff, 10);

      &#{$that}--selected {
        background-color: lighten($main-green, 10);
        border-color: lighten($main-green, 10);
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }

  &--hidden {
    display: none;
  }

  &--quantifiable {
    .Chip-label{
      margin-right: 20px;
      line-height: 50px;
    }
  }

  .Chip-label{
    margin: auto 0;
    padding: 0.3rem 0.5rem;
  }

  .R1Configurations-quantity{
    margin-right: 10px;
  }
}
