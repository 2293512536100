
.Gallery {
  overflow: hidden;
}

.Gallery-pics {
  width: 100%;
  border-top-right-radius: .9rem;
  border-top-left-radius: .9rem;
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: 60%;
  }
}

.Gallery-picContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  transition: 500ms transform ease;

  .Gallery-div-img{
    width: 100%;
    display: flex;
    justify-content: center;
    //flex-direction: column;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.Gallery-dots {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;

  button {
    font-size: 1.8rem;
    margin: 0 .2rem;
    color: $color-gray;

    &.is-selected {
      color: $color-green;
    }
  }
}

.Gallery.hasArrows{
  .Gallery-picContainer img{
    //width: 80%;
    margin: 0 10%;
  }

  .Gallery-pics {
   .Gallery-arrow{
     position: absolute;
     cursor: pointer;
     z-index: 500;
     top: 280px;
     transition: 0.2s all;
     &:hover{
        opacity: 0.8;
     }
     &.arrow-left{
       left: 30px;
     }
     &.arrow-right{
       right: 30px;
       transform: rotate(180deg);
     }
     svg{
       font-size: 60px;
       color: $color-gray-2;
     }
   }
  }
}
