.ProjectHeaderCard {
  display: flex;
  justify-content: space-between;
  h2 {
    font-weight: 400;
    color: #777;
    margin: 0 1rem 0 0!important;
  }
}

.ProjectHeaderCard-row {
  display: flex;
  align-items: center;
}

.ProjectHeaderCard-headRight {
  display: flex;
  align-items: center;

  .icon-button {
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    border-radius: 20px;
  }

  .FolderIcon{
    cursor: pointer;
  }

  & > :not(:last-child) {
    margin-right: 1rem;
  }

  .isSyncing svg{
    -webkit-animation:spin 2s linear infinite;
    -moz-animation:spin 2s linear infinite;
    animation:spin 2s linear infinite;
  }
}

.ProjectHeaderCard .Project-status {
  font-weight: bold;
  display: flex;
  align-items: baseline;
  &-bull {
    border-radius: 50%;
    width: .7rem;
    height: .7rem;
    margin-right: .3rem;


    //$status-colors: (
    //        created : $color-status-created,
    //        noshow :$color-status-noshow,
    //        signed : $color-status-signed,
    //        analysisplu : $color-status-analysisplu,
    //        declarationtravaux : $color-status-declarationtravaux,
    //        planificiation : $color-status-planificiation,
    //        closed : $color-status-closed,
    //        loss : $color-status-loss,
    //        upsell : $color-status-upsell
    //);
    //
    //@each $statut in $status {
    //  &.is-#{$statut} {
    //    &:hover {
    //      border-color: map-get($status-colors, $statut);
    //    }
    //    .ProjectSearch-item-status {
    //      background: map-get($status-colors, $statut);
    //    }
    //    .ProjectSearch-item-statusLabel {
    //      color: map-get($status-colors, $statut);
    //    }
    //  }
    //}
    //
    &.status-signed{
      background-color: $color-status-signed;
    }
    &.status-noshow{
      background-color: $color-status-noshow;
    }
    &.status-loss{
      background-color: $color-status-loss;
    }
    &.status-created{
      background-color: $color-status-created;
    }
    &.status-wc{
      background-color: $color-status-wc;
    }
    &.status-analysisplu{
      background-color: $color-status-analysisplu;
    }
    &.status-mairie{
      background-color: $color-status-mairie;
    }
    &.status-attente-50{
      background-color: $color-status-attente-50;
    }
    &.status-accepte-mairie{
      background-color: $color-status-accepte-mairie;
    }
  }
}

.ProjectHeaderCard-employee{
  cursor: pointer;
  transition: 0.4s all;
  &:hover{
    opacity: 0.8;
  }
}