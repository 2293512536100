.Configs {
  min-height: $main-height;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &-title {
    margin: 0;
    font-weight: 400;
    color: $color-darkgray;
  }

  &-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.75rem;
  }

  &-buttons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
