.Modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000070;
  z-index: 1000;
}

.Modal {
  @extend .card;
  position: relative;
}

.Modal-close {
  position: absolute;
  top: 13px;
  right: 13px;
  color: $color-red;
}

.Modal-loader {

  min-width: 300px;
  text-align: center;

  p{
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
    font-weight: 800;
    letter-spacing: 2px;
  }

  .lds-hourglass {
    margin-top: 10px;
    display: inline-block;
    position: relative;
    width: 120px;
    height: 120px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 48px solid $color-gray;
    border-color: $color-gray transparent $color-gray transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }

}

