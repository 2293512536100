/* ISOK */
.Dashboard-head-time{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  p{
    font-size: 18px;
    text-transform: capitalize;
    margin: 0;
  }

  button.urgent1{
    animation: 6s shake1 infinite alternate;
  }
  button.urgent2{
    animation: 0.6s shake2 infinite alternate;
  }
  button.urgent3{
    animation: 0.5s shake3 infinite alternate;
  }
}

.Dashboard-items{
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  justify-content: space-between;
  > div{
    flex-grow: 1;
    box-sizing: border-box;
    width: 45%;
    margin-bottom: 15px;
    height: 130px;
    &:nth-child(odd){
      margin-right: 15px;
    }
  }
}

.Dashboard-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  //margin-right: 15px;

  .Dashboard-item-icon svg{
    font-size: 40px;
    margin-top: 10px;
    color: $color-darkgray;
  }
  .Dashboard-item-name{
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 0;
  }
  .Dashboard-item-desc{
    font-weight: 400;
    margin-top: 10px;
    font-size: 12px;
  }

  //&.item_spend{
  //  svg, .Dashboard-item-name{
  //    color: $main-red;
  //  }
  //}
  //
  //&.item_project{
  //  svg, .Dashboard-item-name{
  //    color: $alloy-orange;
  //  }
  //}
  //&.item_area{
  //  svg, .Dashboard-item-name{
  //    color: $viridian-green;
  //  }
  //}
  //&.item_stat{
  //  svg, .Dashboard-item-name{
  //    color: $color-darkgray;
  //  }
  //}

  &.wip{
    background: repeating-linear-gradient(
      45deg,
      #fff,
      #fff 10px,
      $color-lightgray 10px,
      $color-lightgray 20px
    );
  }


  .Dashboard-item-wip{
    color: $gamboge;
    position: absolute;
    margin-top: 55px;
    background-color: white;
    font-size: 20px;
    width: 80px;
    padding: 4px;
    text-align: center;
    svg{
      font-size: 24px;
      color: $gamboge;
      display: block;
      margin: 0 auto;
    }
  }
}

@keyframes shake1 {
  91%, 99% {
    transform: translate3d(-1px, 0, 0);
  }
  92%, 98% {
    transform: translate3d(1px, 0, 0);
  }
  93%, 95%, 97% {
    transform: translate3d(-1px, 0, 0);
  }
  94%, 96% {
    transform: translate3d(1px, 0, 0);
  }
}

@keyframes shake2 {
  0% { transform: skewX(-6deg); }
  10% { transform: skewX(6deg); }
  20% { transform: skewX(-6deg); }
  30% { transform: skewX(6deg); }
  40% { transform: skewX(0deg); }
  100% { transform: skewX(0deg); }
}

@keyframes shake3 {
  0% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
  10% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  20% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  100% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}