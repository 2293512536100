.card{
  background-color: white;
  padding: 10px;
  border-radius: 6px;
  box-shadow: $box-shadow;

  &.bg-green{
    background-color: $main-green;
    color: white;
  }

  &.bg-gray{
    background: rgb(165,165,165);
    background: linear-gradient(135deg, rgba(165,165,165,1) 0%, rgba(204,203,203,1) 100%);
    color: white;
  }

  h3 {
    margin-top: 0;
    padding-bottom: .4rem;
    border-bottom: 1px solid $color-gray-2;
  }
}