.Stats{

}

.Stats-head{
  display: flex;
  align-items: center;
  h1{
    margin: 0;
  }
}

.Stats-main-heatmaps{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  h2{
    margin-bottom: -15px;
  }
}

.StatsMonthsDisplay{
  width: 330px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  > div{
    width: 85px;
    text-align: right;
  }

  &.startMonth{}
  &.beginMonth{}
  &.midMonth{
    margin-right: 40px;
  }
  &.endMonth{
    margin-right: 75px;
  }
  &.veryEndMonth{
    margin-right: 115px;
  }
}