.ProgressBar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: .2rem;
  width: 100%;
  overflow: hidden;
  margin-bottom: .5rem;
  transition: 1s background ease;

  &.is-active {
    background: lighten($main-green, 30);

    .ProgressBar-bar {
      background: $main-green;
      animation: progress-indeterminate 2s linear infinite;
    }
  }
}

.ProgressBar-bar {
  position: absolute;
  height: 100%;
  width: 100%;
}

@keyframes progress-indeterminate {
  from { left: -10%; width: 10%; }
  to { left: 100%; width: 10%;}
}