.CustomerDocuments-head {
  height: 91.2px;

  h2 {
    display: flex;
    align-items: center;
    margin-top: 0;
    color: $color-gray;
    font-family: $font-title;
    font-weight: 400;
    font-size: 1.1rem;

    & > svg {
      font-size: 1.7rem;
      margin-top: -.2rem;
      margin-right: .5rem;
    }
  }

  & > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.CustomerDocuments-list {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 0 -30px;
}

.CustomerDocuments-head-buttons{
  display: flex;
  button{
    margin-left: 20px;
  }
}