.Input {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.3rem 0.5rem;
  margin: 1px;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 1;
  }

  &::-webkit-search-cancel-button {
    opacity: 1;
    transform: translateY(-1px);
  }

  &:focus {
    outline: none !important;
    border: 1px solid $main-green;
    box-shadow: 0 0 0 1px $main-green;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
