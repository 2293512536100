.Calendar {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
}

.Calendar-item {
  display: flex;
  height: 2rem;

  &.isPassed .Calendar-item-mark {
    background: $main-green;

    &::after {
      background: $main-green;
    }
  }

  &.isNext .Calendar-item-mark {
    background: $color-pink;

    &::after {
      background: $color-pink;
    }
  }
}

.Calendar-item-mark {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  background: $color-gray;
  border-radius: 50%;
  margin-right: 1rem;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 4px;
    height: 26px;
    background: $color-gray;
    top: 7px;
    left: 6px;
  }
}