.FileUpload {
  align-self: flex-start;
  margin-bottom: 1rem;

  input {
    display: none;
  }
}

.FileUpload-fileName {
  display: flex;
  align-items: center;
  padding-top: .5rem;

  .icon-button {
    height: 16px;
    margin-top: 3px;
    margin-left: .4rem;

    &:hover {
      color: $color-red;
    }
  }

  svg {
    font-size: 1rem;
  }
}
